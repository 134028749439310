@import '../sass/variables';

$top: 4.5rem;

.wrapper {
    padding-top: $top;

    .content {
        background: #fff;
        padding: 20px 10px;
        border-radius: 1px;
        box-shadow: rgba(156, 156, 156, 0.75) 3px 3px 10px 0;
        min-height: calc(100vh - #{$top} - .5rem);

        a, button {
            &:not(.dropdown-item) {
                font-weight: 700;
                color: $secondhand-gray;
                text-transform: uppercase;
                transition: all .4s ease-in-out;
        
                &:hover {
                    text-decoration: none;
                }
        
                &.btn {
                    &:hover, &.active {
                        background: rgba($light-gray, .3);
                        color: $secondhand-gray;
                    }
                }                
            }
        }
                
        .content-body {
            .row.group {
                display: block;
                padding: 10px;

                &:nth-child(even) {
                    background: whitesmoke;
                }                
            }

            .answer {
                background: rgba(#3490dc, .1);
                color: #434343;
                padding: 5px 10px;
                border-radius: 5px;
            }

            .multi-answers {
                .row:not(:last-child) {
                    margin-bottom: .5rem;
                }
            }
        }

        .table td {
            vertical-align: middle;
        }
    }

    input:focus, button:focus, a:focus {
        box-shadow: none !important;
    }

}