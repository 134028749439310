@keyframes click-wave {
    0% { height: 30px; width: 30px; opacity: 0.35; position: relative; }
        100% { height: 100px; width: 100px; margin-left: -40px; margin-top: -40px; opacity: 0; }
}

.fancy-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    transition: all 0.15s ease-out 0s;
    background: #cbd1d8;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    outline: none;
    
    &.round {
        border-radius: 50%;
        &::after {
            border-radius: 50%;
        }
    }

    &:hover {
        background: #9faab7;
    }

    &:checked {
        background: #607d8b;

        &::before {
            height: 20px;
            width: 20px;
            position: absolute;
            content: '✔';
            display: inline-block;
            font-size: 12px;
            text-align: center;
            line-height: 20px;
        }

        &::after {
            -webkit-animation: click-wave 0.65s;
            -moz-animation: click-wave 0.65s;
            animation: click-wave 0.65s;
            background: #40e0d0;
            content: '';
            display: block;
            position: relative;
            z-index: 100;
        }
    }
}
